<template>
  <v-app>
    <v-container :fluid="true" class="pl-sm-16 pr-sm-16" @click="menuFalse()">
      <v-row
        class="mt-3 mb-3 row justify-content-start"
        v-if="last_working_day"
      >
        <!-- Date picker -->
        <v-col cols="12" sm="6" md="3">
          <label
            class="d-flex labels ml-4 font-weight-thin text-left mt-"
            for
            id="cRange"
            >Date Range:</label
          >
          <v-menu nudge-left="0" :close-on-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-col class="d-flex flex-row-reverse">
                <v-subheader
                  class="selected-date mr-3 mt-n3"
                  style="width: 100%"
                  v-bind="attrs"
                  v-on="on"
                  @click="checkClick"
                >
                  {{
                    dateRangeTitle === getToday()
                      ? `Today's`
                      : dateRangeTitle === getYesterDay()
                      ? `Yesterday's`
                      : dateRangeTitle
                  }}
                  <span class="d-flex justify-start ml-auto">
                    <v-icon id="rangeBtn" color="#2758F6"
                      >mdi-calendar-range</v-icon
                    >
                  </span>
                </v-subheader>
              </v-col>
            </template>
            <v-list id="custom-list-style">
              <v-list-item
                style="min-height: 30px"
                v-for="(item, index) in items"
                :key="index"
                @click="checkIfCustomDate(item)"
              >
                <v-list-item-title style="font-size: 13px" class="custom">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            ref="dialog"
            v-model="modal_2"
            :return-value.sync="date_2"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-show="(modal_2_show = false)"
                v-model="picker"
                label="Picker in dialog"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="picker" no-title>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(picker)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <!-- IP selection -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="2"
          class=""
          v-if="selected_company.additional_features_allowed && selected_company.user_status != 'employee'"
        >
          <label class="labels text-left d-block" for>Select IP</label>
          <v-autocomplete
            outlined
            dense
            class="text-left pt-0"
            placeholder="Select IP"
            @change="getTeamMemberCurrent()"
            v-model="selectedIpOption"
            :items="ipOptions"
            item-text="name"
            item-value="value"
          >
          </v-autocomplete>
        </v-col>
        <!-- Time selection -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="2"
          class=""
          v-if="selected_company.additional_features_allowed && selected_company.user_status != 'employee'"
        >
          <label class="labels text-left d-block" for>Select Time</label>
          <v-autocomplete
            outlined
            dense
            class="text-left pt-0"
            placeholder="Select Time"
            @change="getTeamMemberCurrent()"
            v-model="selectedTimeOption"
            :items="timeOption"
            item-text="name"
            item-value="value"
          >
          </v-autocomplete>
        </v-col>
        <!-- Team member selection -->
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
          class=""
          v-if="
            (selectedCompanyUserStatus !== 'employee' && showPermission) ||
            selectedCompanyUserHasTeam
          "
        >
          <label class="labels text-left d-block font-weight-thin" for
            >Team Members</label
          >
          <v-autocomplete
            outlined
            dense
            class="text-left pt-0 work-diary-dropdown"
            placeholder="Select Member"
            v-model="selectedMember"
            :items="workingTeamMembers"
            :loading="fetchMember"
            item-text="name"
            item-value="id"
          >
          </v-autocomplete>
        </v-col>
        <!-- Task selection -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="2"
          class=""
          v-show="!selected_company.additional_features_allowed"
        >
          <label class="labels text-left d-block" for>Select Tasks</label>
          <v-autocomplete
            :disabled="taskData.length <= 1"
            outlined
            dense
            class="text-left pt-0"
            placeholder="Select Tasks"
            v-model="task"
            :items="taskData"
            item-text="title"
            item-value="hash_id"
          >
          </v-autocomplete>
        </v-col>
        <!-- Search button -->
        <v-col cols="12" sm="6" md="1" lg="2" class="">
          <div style="cursor: pointer" class="mt-n3">
            <v-btn
              id="search-workdiary-btn"
              large
              :disabled="selectedMember == ''"
              @click="get_work_diary"
              color="#2758f6"
              class="search_btn white--text px-16 px-md-5 px-lg-10 mt-7"
            >
              <span class="mr-4">
                <v-icon color="white" class="mt-n1 mr-3"
                  >mdi-find-replace</v-icon
                >Search</span
              >
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="Delete d-none" v-if="selectedimages.length && user_allowed">
        <label class="label" for>Selected Shots</label>
        <div @click="delete_shots_array" class="deleteButton">Delete</div>
        <label style="margin-top: 13px">
          <input
            @click="selectAllShots($event)"
            class="selectButton"
            type="checkbox"
          />
          Select/Deselect All
        </label>
      </div>
      <!-- Components Imports -->
      <create-video-modal :video_data="create_video_data"></create-video-modal>
      <WorkDiaryImages
        :screenshots="screenshots"
        :totalWorkedTime="totalWorkedTime"
        :selectedMember="selectedMember"
        v-if="this.last_working_day !== null"
      />
      <!-- Dummy Page For Employeer If Last Working Is Null -->
      <DummyWorkDiary
        v-if="
          selectedCompanyUserStatus != 'employee' &&
          last_day &&
          this.last_working_day == null
        "
      />

      <!-- Dummy Page For Employee If Last Working Is Null -->
      <DummyWorkDiary
        :fromEmployeeSide="fromEmployeeSide"
        v-if="
          last_day &&
          last_working_day == null &&
          selectedCompanyUserStatus == 'employee'
        "
      />
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import $ from "jquery";

export default {
  name: "header-dropdowns",
  components: {
    CreateVideoModal: () => import("@/components/workDiary/CreateVideoModal"),
    DummyWorkDiary: () => import("@/views/DummyPages/DummyWorkDiary"),
    WorkDiaryImages: () => ({
      component: import("@/components/workDiary/WorkDiaryImages"),
    }),
  },
  data() {
    return {
      date_2: new Date().toISOString().substr(0, 10),
      selectedIpOption: "",
      selectedTimeOption: "",
      ipOptions: [
        { name: "All", value: "all" },
        { name: "Inside Offices", value: "inside_office" },
        { name: "Outside Offices", value: "outside_office" },
        { name: "VPN", value: "vpn" }
      ],
      timeOption: [
        { name: "All", value: "all" },
        { name: "Office Hours", value: "office_hour" },
        { name: "Non Office Hours", value: "after_hours" },
      ],
      fromEmployeeSide: true,
      fetchMember: false,
      menu_2: false,
      modal_2: false,
      last_day: false,
      picker: "",
      date: [],
      showPicker: false,
      menu: false,
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        // {id: 3, title:'Last Working Day`s',value: ''},
        { id: 4, title: "Select Date", value: "" },
      ],
      isShow: true,
      view: true,
      show_modal: false,
      create_video_data: "",
      start: moment(),
      end: moment(),
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      selectedMember: "",
      myProjects: [],
      screenshots_stats: [],
      current_members: [],
      selectedimages: [],
      now_members: [],
      result2: "",
      screenshots: [],
      item: {
        value: "",
        text: "",
      },
      searchText: "",
      task_search: "",
      dateRangeTitle: "",
      search_date: null,
      search_member: null,
      filteredData: [],
      taskData: [],
      task: null,
      totalWorkedTime: null,
      last_working_day: null,
      workingTeamMembers: [],
      workingDays: [],
      checkWorkingDays: true,
      custom_search: false,
    };
  },
  watch: {
    picker() {
      this.menu = false;
      this.getWorkFromCustomDate();
    },
    selectedMember: function (val) {
      if (val !== "") {
        this.taskData = [];
        this.task = null;
        this.$root.$emit("reset_video", true);
        this.companyTask();
      }
    },
  },
  computed: {
    ...mapState("custom", [
      "selected_company",
      "diary_member_id",
      "work_diary_date_range",
      "work_diary_date",
      "companies_list",
      "team_members",
      "search_image",
    ]),
    ...mapState("auth", ["user"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.work_diary) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    selectedCompanyUserHasTeam() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    checkPagePermission() {
      if (this.selectedCompanyUserStatus === "secondary_user") {
        const company = this.companies_list.find(
          (c) => c.id === this.$route.params.id
        );
        if (company && company.permission) {
          if (company.permission.work_diary) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    user_allowed() {
      return (
        (this.selectedCompanyUserStatus === "employee" &&
          this.showPermission) ||
        this.selectedMember === this.user.id
      );
    },
    memberData() {
      return this.filteredData;
    },
  },

  beforeDestroy() {
    this.filteredData = [];
  },
  mounted() {
    
    this.selectedTimeOption = this.timeOption[0].value;
    this.selectedIpOption = this.ipOptions[0].value;
    if (this.work_diary_date_range) {
      this.start_date = this.work_diary_date_range;
      this.end_date = this.work_diary_date_range;
      this.last_working_day = this.work_diary_date_range;
      this.start = moment(this.work_diary_date_range);
      this.end = moment(this.work_diary_date_range);
      this.dateRangeTitle = moment(this.work_diary_date_range).format("MMM D, YYYY");
      this.$store.commit("custom/set_work_diary_date_range", "");
      if (this.diary_member_id !== "") {
        this.selectedMember = this.diary_member_id;
        this.$store.commit("custom/set_diary_member_id", "");
        this.getTeamMemberCurrent();
      }
    }
    else {
      this.lastWorkingDay();
    }
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtn").click();
        x.style.display = "none";
      }
    });
    // if (
    //   this.selectedCompanyUserHasTeam ||
    //   (this.selectedCompanyUserStatus !== "employee" && this.showPermission)
    // ) {
    //   this.filteredData = [{ id: null, name: "loading..." }];
    //   this.getTeamMembers();
    //   this.selectedMember = "";
    // } else {
    //   this.selectedMember = this.user.id;
    // }

    if (this.work_diary_date_range) {
      this.start = moment().startOf(this.work_diary_date_range);
      this.start = moment().startOf(this.work_diary_date_range);
      this.end = moment().endOf(this.work_diary_date_range);
      this.start_date = moment()
        .startOf(this.work_diary_date_range)
        .format("YYYY-MM-DD");
      this.end_date = moment()
        .endOf(this.work_diary_date_range)
        .format("YYYY-MM-DD");
      this.$store.commit("custom/set_work_diary_date_range", "");
    }

    this.$root.$on("clicked", (data) => {
      if (
        this.selectedimages.find(
          (value) => value.screenshot_id === data.screenshot_id
        )
      ) {
        let index = this.selectedimages.findIndex(
          (option) => option.screenshot_id === data.screenshot_id
        );
        this.selectedimages.splice(index, 1);
      } else {
        this.selectedimages.push(data);
      }
    });

    this.$root.$on("data-clicked", () => {
      this.get_work_diary();
    });

    $(".mx-calendar-header a").click(() => {
      this.current_dates();
    });

    this.screenshots = [];
    this.$root.$on("createVideoEmit", () => {
      this.createVideo();
    });

    this.search_date = this.$route.params.date;
    this.search_member = this.$route.params.member_id;
    // if (this.search_date && this.search_member) {
    //   this.selectedMember = this.search_member;
    //   (this.start_date = this.search_date),
    //     (this.end_date = this.search_date),
    //     (this.custom_search = true);
    //   this.lastWorkingDay();
    // } else {
    //   this.custom_search = false;
    //   this.lastWorkingDay();
    // }

    this.$nextTick(() => {
      if (this.diary_member_id !== "") {
        this.selectedMember = this.diary_member_id;
        this.get_work_diary();
      }
    });
    if (this.$store.state.custom.diary_member_id != "") {
      this.selectedMember = this.$store.state.custom.diary_member_id;
      this.$store.commit("custom/set_diary_member_id", "");
    }
  },

  methods: {
    menuFalse() {
      this.showPicker = false;
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    getWorkFromCustomDate() {
      this.start_date = this.picker;
      this.end_date = this.picker;
      if (this.selectedMember) {
        this.taskData = [];
        this.task = null;
      }
      if (
        this.selectedCompanyUserHasTeam ||
        (this.selectedCompanyUserStatus !== "employee" && this.showPermission)
      ) {
        this.getTeamMemberCurrent();
        // this.selectedMember = "";
      }

      if (this.selectedMember) {
        this.taskData = [];
        this.task = null;
        this.companyTask();
      }

      this.get_work_diary(this.picker);
      this.menu = false;
      this.dateRangeTitle = moment(this.picker).format("MMM D, YYYY");
    },
    checkDate() {
      if (this.workingDays >= 1) {
        if (this.workingDays == 1) {
          this.dateRangeTitle = "Yesterday";
        } else {
          //this.dateRangeTitle = "Select Date";
        }
      } else {
        this.dateRangeTitle = "Today";
      }
    },
    checkIfCustomDate(date) {
      if (date.id == 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      } else if (date.id == 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      } else if (date.id == 4) {
        // this.showPicker = true;
        this.modal_2 = true;
        // this.dateRangeTitle = "Select Date";
        // document.getElementById("range-date").click();
      }
      if (this.selectedMember) {
        this.taskData = [];
        this.task = null;
      }
      if (
        this.selectedCompanyUserHasTeam ||
        (this.selectedCompanyUserStatus !== "employee" && this.showPermission)
      ) {
        this.getTeamMemberCurrent();
        // this.selectedMember = "";
      }

      if (this.selectedMember) {
        this.taskData = [];
        this.task = null;
        this.companyTask();
      }
      document.getElementById("cRange").click();
    },
    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
    },

    companyTask() {
      let data = {
        company_id: this.$route.params.id,
        member_id: this.selectedMember,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/company_task", data)
        .then((response) => {
          this.taskData = response.data.tasks;
          this.taskData.unshift("All Tasks");
          this.get_work_diary();
        })
        .catch(() => {
          this.taskData = [];
          this.$store.commit("custom/toggle_loader", false);
        });
    },

    /*
        Work Dairy Updated Work Flow
        Get Last working days
      */
    lastWorkingDay() {
      let data = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
        custom_search: this.custom_search,
      };
      if (this.$store.state.custom.last_working_date) {
        this.last_working_day = this.$store.state.custom.last_working_date;
        this.last_day = true;
        this.dateRangeTitle = this.$store.state.custom.last_working_date;
        this.checkWorkingDays = true;
      }
      if (this.$store.state.custom.work_diary_team_members) {
        this.workingTeamMembers = this.$store.state.custom.work_diary_team_members;
      }
      this.$store
        .dispatch("custom/getLastWorkingDays", data)
        .then((response) => {
          this.last_working_day = response.data.data.last_working_date;
          this.last_day = true;
          this.dateRangeTitle = response.data.data.last_working_date;
          if (this.last_working_day == null) {
            this.checkWorkingDays = false;
          } else {
            this.workingTeamMembers = response.data.data.team_members;
            this.$store.commit(
              "custom/set_work_diary_team_members",
              response.data.data.team_members
            );
            this.workingDays = response.data.data.days;
            this.start = moment().subtract(this.workingDays, "day");
            this.end = moment().subtract(this.workingDays, "day");
            this.start_date = this.start.format("YYYY-MM-DD");
            this.end_date = this.end.format("YYYY-MM-DD");
            this.checkDate(this.start_date, this.end_date);
            this.get_work_diary();
          }
        })
        .catch(() => {
          this.checkWorkingDays = false;
        });
    },
    getTeamMembers: async function () {
      let active_members = this.$store.state.custom.team_members;
      if (active_members.length >= 1) {
        this.filteredData = active_members;
      } else {
        let data = await this.$store.dispatch("custom/get_team_members", {
          company_id: this.$route.params.id,
        });
        this.filteredData = data.team_members;
      }
      this.$nextTick(() => {
        if (this.diary_member_id !== "") {
          this.selectedMember = this.diary_member_id;
          this.$forceUpdate();
          this.$store.commit("custom/set_diary_member_id", "");
        }
      });
    },
    selectAllShots(event) {
      if (event.target.checked) {
        document.querySelectorAll(".delete-check").forEach((selector) => {
          selector.checked = true;
          if (!this.selectedimages.includes(selector.value)) {
            this.selectedimages.push(selector.value);
          }
        });
      } else {
        document.querySelectorAll(".delete-check").forEach((selector) => {
          selector.checked = false;
          if (this.selectedimages.includes(selector.value)) {
            this.selectedimages.splice(
              this.selectedimages.indexOf(selector.value),
              1
            );
          }
        });
      }
    },
    reset() {
      this.item = {};
    },
    getTeamMemberCurrent() {
      let data = {
        company_id: this.$route.params.id,
        date: this.start_date,
        selected_ip: this.selectedIpOption,
        selected_time: this.selectedTimeOption,
      };
      this.fetchMember = true;
      this.$store
        .dispatch("custom/getTeamMemberList", data)
        .then((response) => {
          this.fetchMember = false;
          this.workingTeamMembers = response.data.data.team_members;
          if(this.selectedMember) {
            this.get_work_diary();
          }
        })
        .catch(() => {
          this.fetchMember = false;
        });
    },
    get_work_diary() {
      this.loader = false;
      if (
        this.start_date !== "" &&
        this.end_date !== "" &&
        this.selectedMember !== ""
      ) {
        if (this.task === "All Tasks") {
          this.task = null;
        }
        let data = {
          start_date: this.start_date,
          end_date: this.end_date,
          member_id: this.selectedMember,
          task_id: this.task,
          company_id: this.$route.params.id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          selected_ip: this.selectedIpOption,
          selected_time: this.selectedTimeOption,
        };
        if (!this.$store.state.custom.show_loader)
          this.$store.commit("custom/toggle_loader", true);
        this.$store.commit("custom/image_data", false);

        this.$store
          .dispatch("custom/get_work_diary", data)
          .then((response) => {
            this.loader = true;
            if (response.data.error === false) {
              if (response.data.screenshots.length !== 0) {
                this.screenshots = response.data.screenshots;
                this.totalWorkedTime = response.data.total_worked_time;
              } else {
                this.screenshots = [];
              }
              this.$root.$emit("reset_time", 0);
            }
            this.$store.commit("custom/toggle_loader", false);
            this.$store.commit("custom/image_data", true);
          })
          .catch(() => {
            this.$store.commit("custom/toggle_loader", false);
          });
      } else {
        this.screenshots = [];
        this.loader = true;
      }
    },

    current_dates() {
      $(
        "#diary_date_picker .mx-datepicker-popup table tbody tr td"
      ).removeClass("date_present");
      setTimeout(() => this.update_calendar(), 100);
    },
    update_calendar() {
      let dates = this.screenshots_stats.dates_present;
      $(document)
        .find("#diary_date_picker .mx-datepicker-popup table tbody tr td")
        .each(function () {
          dates.find((date) => {
            if ($(this).attr("title") === date.date_present) {
              $(this).addClass("date_present");
            }
          });
        });
    },

    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },

    delete_shots_array() {
      this.$confirm_dialog(
        "You are going to delete these screen shots. If you delete screenshots, your time will be deducted from time log.",
        true,
        (element) => {
          if (element) {
            this.$store
              .dispatch("custom/delete_shots_array", {
                shots_array: this.selectedimages,
              })
              .then((response) => {
                this.$store.commit("custom/toggle_loader", false);
                if (response.data.error === false) {
                  this.selectedimages = [];
                  this.$root.$emit("snack_bar", {
                    show: true,
                    message: response.data.error_msg,
                    snackbarColor: "green",
                  });
                  this.get_work_diary();
                }
              })
              .catch((error) => {
                this.$store.commit("custom/toggle_loader", false);
                if (error.response.data.error === true) {
                  this.$root.$emit("snack_bar", {
                    show: true,
                    message: error.response.data.error_msg,
                    snackbarColor: "red",
                  });
                }
              });
          }
        }
      );
    },
    selectMembers() {
      this.now_members = this.current_members.map((member) => {
        return { text: member.name, value: member.id };
      });
    },
    createVideo: _.debounce(function () {
      let data = {
        start_date: this.start_date,
        end_date: this.end_date,
        member_id: this.selectedMember,
        company_id: this.$route.params.id,
      };
      if (JSON.stringify(this.create_video_data) !== JSON.stringify(data))
        this.create_video_data = data;
      // this.$root.$emit("bv::show::modal", "create_video_modal");
      this.$root.$emit("open_video_modal");
    }, 500),
    // createVideo() {
    //   let data = {
    //     start_date: this.start_date,
    //     end_date: this.end_date,
    //     member_id: this.selectedMember,
    //     company_id: this.$route.params.id,
    //   };
    //   if (JSON.stringify(this.create_video_data) !== JSON.stringify(data))
    //     this.create_video_data = data;
    //   // this.$root.$emit("bv::show::modal", "create_video_modal");
    //   setTimeout( function() {
    //     this.$root.$emit("open_video_modal");
    //   }.bind(this),200);
    // },
    getToday() {
      return new Date().toISOString().slice(0, 10);
    },
    getYesterDay() {
      var prev_date = new Date();
      prev_date.setDate(prev_date.getDate() - 1);
      return prev_date.toISOString().slice(0, 10);
    },
  },
};
</script>
<style scoped>
.selected-date {
  border: 1px solid rgba(54, 69, 79, 0.32) !important;
  font-size: 12px !important;
  display: flex !important;
  height: 42px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
/* .search_btn {
  font-weight: 600;
  font-size: 16px;
  margin-top: 27px;
  width: 190px;
  height: 33px;
  cursor: pointer;
} */
.date-picker-icon {
  margin-left: 150px;
}
.calender-btn {
  width: 200px !important;
  z-index: 0.9 !important;
}
</style>
